<template>
  <div>
     <LoaderDoom
      LoaderDoomClass="LoaderDoom"
      spinnerClass="animate-spin w-10 text-blue-400"
    />
  </div>
</template>
<script setup >
import LoaderDoom from '@/components/General/LoaderDoom.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import {  useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const store = useStore();

onMounted( async () => {
    await store.dispatch('auth/removeToken');
    await store.dispatch('auth/setUser', {});
    await store.dispatch('auth/cleanStore');
    const { token, redirectTo } = route.query;
    if(token && typeof token === 'string'){
      await store.dispatch('auth/setToken', token);
    }
    if(redirectTo && typeof redirectTo === 'string'){
      let [path, queryString] = redirectTo.split('?');
      
      if(queryString){
        let query = Object.fromEntries(new URLSearchParams(queryString));
        router.push({ path: path, query: query });
      } else {
        router.push({ path: path });
      }
    }
})


</script>
